import React, { useRef, useState } from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";
import SEO from "@shared/seo";
import { Query, SanityPageConnection } from "@graphql-types";
import { Container, A } from "@util/standard";
import {
  mediaQuery,
  Eurostile_BQ,
  INTER_FONT,
  fontWeights,
  PRIMARY_COLOR,
  LIGHTGREY_COLOR,
  SECONDARY_COLOR,
  GREYBG_COLOR,
  FONT_LINK,
  BLACK_COLOR,
} from "@util/constants";
import { Hero } from "@global";
import Layout from "@shared/layout";
import { TextCta } from "@sections";
// @ts-ignore
import zuruXshotImg from "@static/assets/zuru-x-shot.svg";
// @ts-ignore
import lockBlasterImg from "@static/assets/lock-blaster.svg";
// @ts-ignore
import infoIcon from "@static/assets/icon/info-icon.svg";
// @ts-ignore
import leftArrow from "@static/assets/icon/left-arrow.svg";
// @ts-ignore
import rightArrow from "@static/assets/icon/right-arrow.svg";
// @ts-ignore
import downArrow from "@static/assets/icon/down-arrow.svg";
import Sections from "@shared/sections";
// @ts-ignore
import lockBlaster331 from "@static/assets/lockblasterVideo/331.mp4";
// @ts-ignore
import lockBlaster238 from "@static/assets/lockblasterVideo/238.mp4";
// @ts-ignore
import lockBlaster161 from "@static/assets/lockblasterVideo/161.mp4";
// @ts-ignore
import lockBlaster072 from "@static/assets/lockblasterVideo/072.mp4";
// @ts-ignore
import lockBlaster954 from "@static/assets/lockblasterVideo/954.mp4";
// @ts-ignore
import lockBlaster726 from "@static/assets/lockblasterVideo/726.mp4";

const LocBlasterPage = styled(Container)`
  display:block;
  text-transform:uppercase;
  font-family: ${Eurostile_BQ};
  font-weight: ${fontWeights.theme};
  background: ${GREYBG_COLOR};
  letter-spacing:-0.9px;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgb(0 0 0 / 45%);
    z-index:-9;
    opacity:0;
    transition:all 0.6s ease;
  }
    &.model-open {
      &:before{
        z-index: 9;
        opacity:1;
      }
      .model-pop-up {
        z-index:99;
        opacity:1;
      }
    }
    .loc-blaster-video {
      max-height: 100vh;
    }
  .show-sm {
    display:none;
    ${mediaQuery.smallDown} {
      display:inline-block;
    }
  }
}
  .scroll-down {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    bottom: 60px;
    position: absolute;
    padding: 10px 40px;
    font-family:${Eurostile_BQ};
    font-weight:${fontWeights.theme};
    font-size: 12px;
    line-height: 22px;
    ${mediaQuery.mediumDown} {
      display:none;
    }
    .scroll-down-left-mr {
      padding-right: 12px;
    }
    .scroll-down-data {
      cursor: pointer;
    }
  }
  .full-page-video {
    overflow: hidden;
    width:100%;
    height:100%;
     .one-overlap {
        position:absolute;
        left:0;
        top:50px;
        width:100%;
        z-index:8;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding:10px 40px;
        z-index: 1;
        ${mediaQuery.largeDown} {
          padding:9px 30px;
        }
        ${mediaQuery.mediumDown} {
          padding:7px 20px;
            & > div {
              width:50%;
              img {
                max-width:77%;
                height:auto;
              }
            }
          }
        ${mediaQuery.smallDown} {
          padding:5px 18px;
        }
        .right{
              text-align: right;
        }
      }
    }
  }
`;

const Unlocked = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:22px 40px;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  color: ${SECONDARY_COLOR};
  .product-code {
    display: flex;
    span {
      font-size: 90px;
    }
  }

    @media (max-width: 320px) {
     .product-code {
       span {
         font-size: 84px !important;
        }
      }
    }
  
  @media (max-width: 280px) {
    .product-code {
      span {
        font-size: 70px !important;
      }
    }
  }
  ${mediaQuery.smallUp} {
    font-size:46px;
    line-height:95px;
    padding:20px;
    .product-code {
      span {
        font-size: 46px;
      }
    }
  }

  ${mediaQuery.mediumUp} {
    font-size: 60px;
    line-height: 110px;
    .product-code {
      span {
        font-size: 60px;
      }
    }
  }

  ${mediaQuery.largeUp} {
    font-size: 80px;
    line-height: 120px;
    .product-code {
      span {
        font-size: 80px;
      }
    }
  }

  ${mediaQuery.wideUp} {
    font-size: 129px;
    line-height: 135px;
  }

  ${mediaQuery.smallDown} {
    font-size: 38px;
    line-height: 42px;
    padding: 22px 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
      .unlocked-text{
        font-size: 44px;
        margin:0 0 10px 0;
      }
   }
   @media (max-width: 400px) {
     .unlocked-text {
        font-size: 40px;
     }
   }

    @media (max-width: 320px) {
      .unlocked-text {
          font-size: 36px;
      }
    }

    @media (max-width: 280px) {
      .unlocked-text {
          font-size: 32px !important;
      }
    }
    
  .ptb-none {
    padding:0;
    width:auto;
    display: flex;
    text-transform: uppercase;
    ${mediaQuery.smallDown} {
      width:100%;
    }
    .column {
      ${mediaQuery.smallDown} {
        width:100%;
      }
      .number-box {
        padding:5px 8px;
        border:1px solid ${SECONDARY_COLOR};
        border-radius:1px;
        color: ${SECONDARY_COLOR};
        line-height:1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        letter-spacing:-1.1px;
        
        @media only screen and (max-width: 1024px) and (min-width: 912px) and (max-height: 1368px) and (min-height: 1280px) {
          border:2px solid ${SECONDARY_COLOR};
        }

        ${mediaQuery.smallDown} {
          padding:2px 8px;
          justify-content: center;
          .product-code {
            span {
              font-size: 107px;
            }
          }
        }
      }
      .no-border {
        border: none;
      }
    }
    .code {
      position: relative;
      display: inline-block;
      font-size: 18px;
      margin-left: -22px;
      ${mediaQuery.smallUp} {
        transform: rotateZ(270deg);
      }
      ${mediaQuery.wideDown} {
        font-size: 15px;
        margin-left: -20px;
      }
      ${mediaQuery.largeDown} {
        font-size:13px;
      }
      ${mediaQuery.mediumDown} {
        font-size:11px;
        margin:0;
        margin-left: -12px;
      }
      ${mediaQuery.smallDown} {
        margin-left:5px;
        margin-right:15px;
        font-size: 20px;
        line-height:15px;
        display: inline-block; 
        text-transform: uppercase; 
        writing-mode: vertical-rl;
        text-orientation: mixed; 
        transform: rotate(-180deg);
      }
      @media (max-width: 280px) {
        font-size: 14px;
      }
    }
    .number {
      position: relative;
      font-size: 82px;
      ${mediaQuery.wideDown} {
        font-size:60px;
      }
      ${mediaQuery.largeDown} {
        font-size:50px;
      }
      ${mediaQuery.mediumDown} {
        font-size:40px;
        margin:0;    
      }
       ${mediaQuery.smallDown} {
        font-size: 99px;
      }
    }
  }
}
`;

const ModelPopUp = styled.div`
  background: ${PRIMARY_COLOR};
  border: 1px solid ${BLACK_COLOR};
  color: ${LIGHTGREY_COLOR};
  padding:26px;
  font-style: normal;
  font-weight: 500;
  font-size: 15.3694px;
  line-height: 28px;
  position: fixed;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
  z-index:-9;
  opacity:0;
  transition:all 0.6s ease;
  max-width:355px;
  ${mediaQuery.mediumDown} {
    width:calc(100% - 48px);
    padding:22px;
  } 
  @media (max-width: 320px) {
   width:100%;
  }
  .close{
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 88;
    width:20px;
    height:20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size:9px;
  }
  .model-title {
    margin:0 0 20px 0;
    ${mediaQuery.smallDown} {
      margin:0 0 12px 0;
    }
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }
  .sub-title {
    margin:0 0 15px 0;
    font-size:18px;
    line-height:1.2;
    text-transform:initial;
    font-family: ${INTER_FONT};
  }
  .codes{
    display: flex;
    flex-wrap: wrap;
    gap:14px 18px;
    .code {
      font-style: normal;
      font-weight: 500;
      font-size: 43.8903px;
      line-height: 56px;
      color: ${LIGHTGREY_COLOR};
      border:1px solid ${LIGHTGREY_COLOR};
      width: calc(50% - 9px);
      padding:2px 9px;
      text-align: center;
      ${mediaQuery.smallDown} {
        font-size: 33px;
        line-height:45px;
        padding:2px 7px;
      }
      &.disable{
        color: rgba(192, 191, 191, 0.3);
        mix-blend-mode: normal;
        border: 1px solid rgba(192, 191, 191, 0.3);
      }
    }
  }
}

`;

const LinkModelPopup = styled.div`
  background: ${PRIMARY_COLOR};
  border: 1px solid ${BLACK_COLOR};
  color: ${LIGHTGREY_COLOR};
  padding: 20px 28px 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 15.3694px;
  line-height: 28px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  transition: all 0.6s ease;
  width: 100%;
  max-width: 336px;
  @media (max-width: 320px) {
    padding: 20px 18px 32px;
  }
  .link-model-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 120%;
    color: #c0bfbf;
    margin: 0 0 20px 0;
  }
  .link-model-content {
    font-family: ${INTER_FONT};
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    text-transform: initial;
  }
  .link-model-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 29px;
  }
  .link-model-footer-item {
    font-family: "Eurostile BQ";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    cursor: pointer;
  }
  .grey-border {
    border: 1px solid ${FONT_LINK};
    border-radius: 30px;
    padding: 8px 14px;
  }
  .link-model-text {
    color: ${FONT_LINK};
    margin: 0;
  }
`;

const LockBlasterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  padding:5px 40px;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: #090E10;
  color: #FFFFFF;
}
  ${mediaQuery.largeDown} {
    padding:5px 18px;
  }
`;

const LeftSide = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  text-transform:uppercase;
  align-items: center;
    span{
      img{
        margin:0 9px 0 0;

        @media (max-width: 280px) {
          margin:0 2px 0 0;
        }
      }
      &.show-sm{
        margin:0;
      }
    }
  }
`;

const NotWorkingPopUp = styled.div`
  // margin:0 0 0 110px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  text-decoration: underline ${SECONDARY_COLOR};
    span {
      margin:0 0 0 7px;
    }
}
`;

const BackToMainPagePopUp = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
`;

const RightSide = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  .copy {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
     ${mediaQuery.smallDown} {
      width:100%;
     }
   }
}
`;
interface Data extends Query {
  altRegions: SanityPageConnection;
}
interface Props extends PageProps {
  data: Data;
}

const codeArr = [
  { code: "331", name: "code-1" },
  { code: "238", name: "code-2" },
  { code: "161", name: "code-3" },
  { code: "072", name: "code-4" },
  { code: "954", name: "code-5" },
  { code: "726", name: "code-6" },
];

export default function pageTemplate({ data }: Props) {
  if (data == null) return null;

  const { sanityPage, altRegions } = data;
  const altRegionsOrganised = altRegions.nodes.map(alt => alt.region);

  if (sanityPage == null) return null;

  const { seo, sections, slug, region, hero, headerDesign } = sanityPage;
  const videoRef = useRef(null);
  const [model, setModel] = useState(false);
  const [linkModel, setLinkModel] = useState(false);
  const [showCountUp, setShowCountUp] = useState(false);

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    // @ts-ignore
    if (video.currentTime >= video.duration - 2) {
      setShowCountUp(true);
    }
  };

  const openLinkModelPopUp = () => {
    setLinkModel(true);
    setModel(false);
  };
  const closeLinkModelPopUp = () => {
    setLinkModel(false);
  };
  const closeModelPopUp = () => {
    setModel(false);
  };
  const openModelPopUp = () => {
    setModel(true);
    setLinkModel(false);
  };

  let disableCode: string | null | undefined = null;
  let videoUrl: string = "";
  if (slug?.current?.includes(`brands/x-shot/lock-blaster/code`)) {
    const codeFromSlug = slug?.current?.split("/").pop();
    disableCode = codeArr.find(item => item.name === codeFromSlug)?.code;

    switch (disableCode) {
      case "331":
        videoUrl = lockBlaster331;
        break;
      case "726":
        videoUrl = lockBlaster726;
        break;
      case "238":
        videoUrl = lockBlaster238;
        break;
      case "954":
        videoUrl = lockBlaster954;
        break;
      case "161":
        videoUrl = lockBlaster161;
        break;
      case "072":
        videoUrl = lockBlaster072;
        break;
      default:
        videoUrl = "";
        break;
    }
  }

  const productRecallPage =
    slug?.current?.includes("voluntary-recall-notice") || slug?.current?.includes("our-response");

  return (
    <>
      <SEO
        seoData={seo}
        slug={slug?.current as string}
        alternateRegions={altRegionsOrganised}
        currentIban={region?.iban}
      />
      <Layout page={slug?.current}>
        {productRecallPage ? (
          <div className="empty-speacing-div"></div>
        ) : (
          <Hero data={hero} useFullHeight />
        )}

        {/* Below conditions is use for just to show below component only the url mention in condition */}

        {slug?.current?.includes(`brands/x-shot/lock-blaster/code`) && (
          <LocBlasterPage className={`loc-blaster-page ${model || linkModel ? "model-open" : ""}`}>
            {model && (
              <ModelPopUp className="model-pop-up">
                <div className="close" onClick={closeModelPopUp}>
                  {" "}
                  X{" "}
                </div>
                <div className="model-title">CODE NOT WORKING?</div>
                <div className="sub-title">Try one of the backup codes:</div>
                <div className="codes">
                  {codeArr.map(({ code }) => {
                    return (
                      <div className={`code ${disableCode === code ? "disable" : ""}`}>{code}</div>
                    );
                  })}
                </div>
              </ModelPopUp>
            )}
            {linkModel && (
              <LinkModelPopup className="model-pop-up">
                <div className="link-model-title">WARNING</div>
                <div className="link-model-content">
                  You are now leaving the
                  <br />
                  <b>X-SHOT Lock Blaster</b>
                  <br />
                  Website. Please obtain
                  <br />
                  parental consent if required.
                </div>
                <div className="link-model-footer">
                  <div className="link-model-footer-item" onClick={closeLinkModelPopUp}>
                    STAY HERE
                  </div>
                  <div className="link-model-footer-item">
                    <A href="/" className=" grey-border">
                      <span className="link-model-text">CONTINUE </span>
                      <span>
                        <img src={rightArrow} alt="Back-Arrow" width={12} height={9} />
                      </span>{" "}
                    </A>
                  </div>
                </div>
              </LinkModelPopup>
            )}
            <LockBlasterContainer>
              <LeftSide>
                <BackToMainPagePopUp>
                  <span className="hide-sm" onClick={openLinkModelPopUp}>
                    <span>
                      <img src={leftArrow} alt="Back-Arrow" width={12} height={9} />
                    </span>{" "}
                    MAIN SITE
                  </span>
                </BackToMainPagePopUp>
              </LeftSide>

              <RightSide>
                <NotWorkingPopUp onClick={openModelPopUp}>CODE NOT WORKING?</NotWorkingPopUp>
              </RightSide>
            </LockBlasterContainer>

            <Container height="100vh" position="relative" width="100%">
              <div className="full-page-video snap-align-start">
                <div className="one-overlap">
                  <div className="left">
                    <img src={zuruXshotImg} alt="Zuru X-Shot" width={196} height={49} />
                  </div>
                  <div className="right">
                    <img src={lockBlasterImg} alt="Zuru X-Shot" width={201} height={47} />
                  </div>
                </div>

                <div className="loc-blaster-video">
                  <video
                    preload="auto"
                    src={videoUrl}
                    ref={videoRef}
                    autoPlay
                    playsInline
                    muted
                    loop
                    onTimeUpdate={handleTimeUpdate}
                  ></video>
                </div>
              </div>

              {showCountUp && (
                <Unlocked>
                  <span className="unlocked-text">UNLOCKED</span>
                  {sections &&
                    sections.map(section => {
                      if (section) {
                        if ("content" in section) {
                          return (
                            <>
                              <TextCta key={section?._key} data={section} />
                            </>
                          );
                        }
                      }
                    })}
                </Unlocked>
              )}
            </Container>
          </LocBlasterPage>
        )}

        {/* Below conditions is use for just to Hide below component only the url mention in condition */}

        {sections &&
          !slug?.current?.includes(`brands/x-shot/lock-blaster/code`) &&
          sections.map(section => <Sections key={section?._key} data={section} />)}
      </Layout>
    </>
  );
}

export const query = graphql`
  query Page($slug: String, $iban: String) {
    sanityPage(slug: { current: { eq: $slug } }, region: { iban: { eq: $iban } }) {
      seo {
        ...sanitySeo
      }
      hero {
        ...sanityHero
      }
      sections {
        ...sanitySections
      }
      title
      region {
        iban
      }
      slug {
        current
      }
      headerDesign
    }
    altRegions: allSanityPage(
      filter: { slug: { current: { eq: $slug } }, isHidden: { ne: true } }
    ) {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
